.content {
}

.heading {
 color: black;
 font-size: 22px;
}

.dateString {
  color: rgba(97, 164, 159, 1);
  font-weight: 500;
}

.addressAndButton {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.detailsBtn {
  background-color: rgba(97, 164, 159, 1);
  color: white;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 30px;
}