.paginationBttns {
    width: 100%;
    height: 50px;
    list-style: none;
    display: flex;
    justify-content: right;
    margin-top: 10px;
  }

  .paginationBttns li:not(:nth-child(1)):not(:nth-last-child(1)){
    display:none;
  }

  .paginationBttns a.previousBttn {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid black;
    color: white;
    background-color: #323232;
    cursor: pointer;
  }

  .paginationBttns a.nextBttn {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid black;
    color: white;
    background-color: #323232;
    cursor: pointer;
  }

  
  
  .paginationBttns a:hover {
    color: white;
    background-color: #323232;
  }
  
  .paginationActive  a{
    color: #323232;
    background-color: white;
    display:none;
  }

  
  .paginationDisabled a {
    color: #323232;
    background-color: #323232;  
  }

  .paginationDisabled a:hover {
    color: #323232;
    background-color: #323232;
  }
