.MuiOutlinedInput-root {
    position: relative;
    border-radius: 4px;
    max-height: 40px;
}

.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    border-radius: 5px;
}