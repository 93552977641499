.rdt input.form-control {
    border: 0;
    background-image: linear-gradient(#9c27b0, #9c27b0),
      linear-gradient(#d2d2d2, #d2d2d2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    display: block;
    width: 100%;
    color: #555;
    display: none !important;
  }
  
  .rdtPicker {
    display: none;
    position: absolute;
    width: 260px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    border-radius: 0.125rem;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-width: 160px;
    top: 19px !important;
    left: -23px !important;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
    border-color: white !important;
    border-collapse: collapse;
    color: #323232 !important;
  }
  
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 25px !important;
    width: 90%;
    margin-left: 34px;
  }
  
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
  
  .rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px;
  }

  
  .rdtPicker thead tr:first-child th:hover {
    background: #FFC821 !important;
  }
  
  .rdtPicker .dow,
  .rdtPicker th.rdtSwitch,
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev,
  .rdtPicker .rdtTimeToggle {
    color:   #323232 !important
  }
  
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #FFC821 !important;
    cursor: pointer;
  }