.ct-series-a .ct-slice-donut {
  /* Colour of your bars */
  stroke: #ffc821 !important;
  stroke-width: 200;
}
.ct-series-b .ct-slice-donut {
  /* Colour of your bars */
  stroke: #d9d9d9;
  stroke-width: 200;
}
.ct-series-c .ct-slice-donut {
  /* Colour of your bars */
  stroke: #323232;
  stroke-width: 200;
}
.ct-series-d .ct-slice-donut {
  /* Colour of your bars */
  stroke: #F5EB29 !important;
  stroke-width: 200;
}
.ct-series-e .ct-slice-donut {
  /* Colour of your bars */
  stroke: #929292;
  stroke-width: 200;
}
.ct-series-f .ct-slice-donut {
  /* Colour of your bars */
  stroke: #ffc821 !important;
  stroke-width: 200;
}
.ct-series-g .ct-slice-donut {
  /* Colour of your bars */
  stroke: #d9d9d9;
  stroke-width: 200;
}
.ct-series-h .ct-slice-donut {
  /* Colour of your bars */
  stroke: #323232;
  stroke-width: 200;
}

.ct-chart-donut .ct-label {
  fill: white;
}
