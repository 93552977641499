.FormGroup {
	margin: 15px 0px 30px 0px;
	padding: 20px;
	border-style: none;
	background: #FFFFFF;
	will-change: opacity, transform;
	border-radius: 4px;
	color: #323232;
	z-index: 9999;
	font-family: 'Archivo';
	font-weight: 600 !important;
	font-size: 17px,
}
.CardField {
	color: #323232;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 20px;
}

.closeButton {
	display: block;
	font-size: 20px;
	width: 45%;
	height: 40px;
	/* margin: 40px 15px 0; */
	background-color: #323232;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
	border-radius: 4px;
	color: white;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
}

.closeButton:active {
	background-color: rgb(238, 189, 9);
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgb(238, 189, 9);
	transform: scale(0.99);
}

.payButton {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	width: 45%;
	height: 40px;
	/* margin: 40px 15px 0; */
	background-color: #FFC821;
	color: #323232;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgb(94, 177, 227);
	border-radius: 4px;
	font-weight: 600;
	cursor: pointer;
	transition: all 100ms ease-in-out;
	will-change: transform, background-color, box-shadow;
	border: none;
	padding: 20px;

}

.payButton:active {
	background-color: rgb(94, 177, 227);
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgb(94, 177, 227);
	transform: scale(0.99);
}


h1,
h3 {
	text-align: center;
}